import 'raf';
import 'css-element-queries';
import 'Vendors/sticky-sidebar/jquery.sticky-sidebar';
import { GetBreakpointWidth } from 'Utils/globals-bp';
import { updateUI } from 'SrcJS/script--updateUI';

//$(document).ready(function () {
AppLoader.ready(function () {
    var $leftMenu = $('.sidebar-nav');
    if ($leftMenu.length > 0) {
        const wrapperClassName = 'sidebar-nav__wrapper';
        const wrapperSelector = '.' + wrapperClassName;
        var leftMenuSidebar = null;

        function apply_sidebar_leftMenu() {
            let bpWidth = GetBreakpointWidth();
            let currentSidebar = $leftMenu.closest(wrapperSelector).data('stickySidebar');
            if (bpWidth >= 1024) {
                if (!currentSidebar) {
                    var wrapper = $('<div></div>')
                        //.attr('id', wrapperId)
                        .addClass(wrapperClassName);
                    $leftMenu.wrapAll(function () {
                        if ($(this).closest(wrapperSelector).length == 0) {
                            return wrapper;
                        }
                    });
                    let $wrapper = $leftMenu.parent();
                    leftMenuSidebar = $wrapper.stickySidebar({
                        topSpacing: 0,
                        bottomSpacing: 0,
                        containerSelector: '.sidebar-nav__parent',
                        innerWrapperSelector: '.sidebar-nav',
                        resizeSensor: true,
                        stickyClass: 'is-affixed',
                        minWidth: 0
                    }).data('stickySidebar');
                    updateUI();
                }
            } else {
                if (currentSidebar) {
                    leftMenuSidebar = $leftMenu.closest(wrapperSelector).stickySidebar('destroy').data('stickySidebar');
                    $leftMenu.unwrap();
                    updateUI();
                }
            }
        }

        apply_sidebar_leftMenu();
        $(window).resize(apply_sidebar_leftMenu);
    }
});