import 'appstart';
//import 'SCSSEntries/oneColumnWithMenu.scss';
import 'left_menu';
import 'jquery-match-height';
//import { /*datepickerHelpText,*/ addDays } from 'Utils/datepicker-helper';



//AppLoader.setReady();


/* check if page use round header caption (SF customer servervice) or round header image (staff pages) */

function roundHeaderCaption() {
	if ($(".header-slider__caption").length == 1 && $(".use-round-header-caption").length == 1) {
		var headerCaption = $(".header-slider__caption").html();
		var color = $(".use-round-header-caption").attr("data-color") || "ffffff";
		var backgroundColor = $(".use-round-header-caption").attr("data-background") || "377982";
		var bubble = "<div class='header-caption-bubble'><div class='bubble-container' style='color:#" + color + ";background-color:#" + backgroundColor + "'><div class='bubble-content'>" + headerCaption + "</div></div></div>";
		$(".header-slider__caption").remove();
		$(".breadcrumbs").before(bubble);
	}

};

function roundHeaderImage() {
	if ($(".round-header-image").length == 1) {
		var headerImage = $(".round-header-image").attr("src");
		headerImage = headerImage + "?w=350&h=350&fit=facearea&facepad=3&auto=compress,format";
		var bubble = "<div class='header-caption-bubble header-caption-bubble-image'><div class='bubble-container' style='background-image:url(" + headerImage + ")'></div></div>";
		$(".breadcrumbs").before(bubble);
		$(".round-header-image").remove();
		$(".header-slider__caption").addClass("no-shadow");
	}

};

//// used on view (/Views/Pages/QuestionPage.cshtml)
//global.addDays = addDays;

$(document).ready(function () {
	roundHeaderCaption();
	roundHeaderImage();
});